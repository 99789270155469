import React, { useEffect, useState } from 'react'
import { getAnalysis, getCase, getSample } from '../services'
import { SamplesTable } from '../SamplesTable/SamplesTable'
import { AnalysisSummary } from '../AnalysisSummary/AnalysisSummary'
import { Loading } from '../../Common/Loading'
import { Card } from 'antd'
import { PageNotFound } from '../../Common/PageNotFound'

interface AnalysisComponentProps {
  match: any
}

const Analysis = (props: AnalysisComponentProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [notFound, setNotFound] = useState<boolean>(false)
  const [analysis, setAnalysis] = useState<any>({})
  const [family, setFamily] = useState<any>()
  const [samples, setSamples] = useState<any>([])
  const { match } = props

  useEffect(() => {
    if (match?.params?.familyId) {
      getAnalysis(match.params.familyId)
        .then((response) => {
          setAnalysis(response)
          getCase(response?.case_id).then((caseResponse) => {
            const tempCase: any = caseResponse?.cases?.filter(
              (caseObj) => caseObj.internal_id === response.case_id
            )[0]
            setFamily(tempCase)
            tempCase?.links.forEach((link) => {
              getSample(link.sample.internal_id).then((response) =>
                setSamples((samples) => [response, ...samples])
              )
            })
          })
        })
        .then(() => setIsLoading(false))
        .catch(() => {
          setIsLoading(false)
          setNotFound(true)
        })
    }
  }, [])

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    )
  }

  if (notFound) {
    return (
      <>
        <PageNotFound />
      </>
    )
  }

  return (
    <>
      <Card>
        <AnalysisSummary analysis={analysis} family={family} />
        <SamplesTable samples={samples} />
      </Card>
    </>
  )
}

export const AnalysisPage = React.memo(Analysis)
