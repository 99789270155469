import React, { useEffect, useState } from 'react'
import styles from './App.module.css'
import './index.css'
import { ConfigProvider, Layout, theme } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { Routes } from './modules/Routes'
import { connect } from 'react-redux'
import { RootState } from './domain/rootReducer'
import {
  setUserInfo as setSettingsAction,
  resetUserInfo as resetSettingsAction,
  setGoogleToken as setGoogleTokenAction,
  resetGoogleToken as resetGoogleTokenAction,
  setAnalyses as setAnalysesAction,
  setThemeMode as setThemeModeAction,
} from './domain/settings/slice'
import { firebaseConfigProd, firebaseConfigStage } from './modules/Common/firebaseConfig'
import firebase from 'firebase/compat/app'
import { OpenNotification, setTapTitleFromPath } from './modules/Common/services/helpers/helpers'
/* import { googleLogout, GoogleOAuthProvider } from '@react-oauth/google' */
import { anonymousFirebaseSignIn } from './modules/Common/FirebaseAuth'
import { BackToTop } from './modules/Common/BackToTop/BackToTop'
import { FooterComponent } from './modules/Common/FooterComponent/FooterComponent'
import jwt_decode from 'jwt-decode'
import { AppHeader } from './app/AppHeader'
import { useOidcAuth } from './hook/useOidcAuth'
/* import { refreshToken } from './modules/Trailblazer/services'
 */
const mapDispatch = {
  setUserInfo: setSettingsAction,
  resetUserInfo: resetSettingsAction,
  setAnalyses: setAnalysesAction,
  setGoogleToken: setGoogleTokenAction,
  resetGoogleToken: resetGoogleTokenAction,
  setThemeMode: setThemeModeAction,
} as const

const mapState = ({ settings }: RootState) => ({ settings } as const)
type Props = ReturnType<typeof mapState> & typeof mapDispatch

const { Content } = Layout

export const AppComponent = ({
  settings,
  setUserInfo,
  resetUserInfo,
  setGoogleToken,
  resetGoogleToken,
  setThemeMode,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  /* const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false) */
  const location = useLocation()
  const [darkMode, setDarkMode] = useState<boolean>(false)
  /* const auth = useAuth() */

  const { login, logout, token, isAuthenticated } = useOidcAuth()

  const { REACT_APP_GOOGLE_OAUTH_CLIENT_ID, REACT_APP_ENV } = process.env
  /* const clientId = REACT_APP_GOOGLE_OAUTH_CLIENT_ID ? REACT_APP_GOOGLE_OAUTH_CLIENT_ID : 'no-id'
  const history = useHistory() */

  if (!firebase.apps.length) {
    firebase.initializeApp(REACT_APP_ENV === 'prod' ? firebaseConfigProd : firebaseConfigStage)
  } else {
    firebase.app()
  }

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme')
    if (storedTheme !== null) {
      setDarkMode(storedTheme === 'true')
      setThemeMode(storedTheme === 'true')
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
      setDarkMode(prefersDark)
      setThemeMode(prefersDark)
      localStorage.setItem('theme', String(prefersDark))
    }
  }, [])

  /*  useEffect(() => {
    console.log('useEffect')
    const tokenFromLocalStorage = localStorage.getItem('token')
    if (tokenFromLocalStorage) {
      const decodedToken = jwt_decode(tokenFromLocalStorage) as { exp: number }
      if (decodedToken.exp * 1000 < Date.now()) {
        logout()
      } else {
        onLoginSuccess(tokenFromLocalStorage)
      }
    }
  }, []) */

  /*   useEffect(() => {
    const interval = setInterval(async () => {
      const token = await refreshToken()
      setGoogleToken(token)
      localStorage.setItem('token', token)
    }, 900000)
    return () => clearInterval(interval)
  }, [isLoggedIn]) */

  useEffect(() => {
    if (token) {
      onLoginSuccess(token)
    }
  }, [token])

  useEffect(() => {
    if (!isAuthenticated) {
      resetUserInfo()
      resetGoogleToken()
      setIsLoaded(true)
      localStorage.removeItem('token')
    }
  }, [isAuthenticated])

  useEffect(() => {
    setTapTitleFromPath(location.pathname)
  }, [location.pathname])

  const onLoginSuccess = (token: any) => {
    console.log('onLoginSuccess')
    console.log(token)
    const decodedToken = jwt_decode(token) as { picture: string; email: string; given_name: string }
    const { picture, email, given_name } = decodedToken
    setUserInfo({ imageUrl: picture, email, givenName: given_name })
    anonymousFirebaseSignIn()
    setGoogleToken(token)
    setIsLoaded(true)
    /* setIsLoggedIn(true) */
    localStorage.setItem('token', token)
  }

  /* const logout = () => {
    resetUserInfo()
    googleLogout()
    resetGoogleToken()
    localStorage.removeItem('token')
    setIsLoaded(true)
    setIsLoggedIn(false)
    history.push('/')
  } */

  /*  const logout = () => {
    return auth.signoutRedirect()
  } */

  const onLoginFailure = () => {
    resetUserInfo()
    resetGoogleToken()
    setIsLoaded(true)
    localStorage.removeItem('token')
    OpenNotification({
      type: 'error',
      message: 'Could not login',
      description: 'Something went wrong',
    })
  }

  const themeSwitch = () => {
    const newTheme = !darkMode
    setDarkMode(newTheme)
    setThemeMode(newTheme)
    localStorage.setItem('theme', String(newTheme))
  }

  return (
    <>
      <ConfigProvider
        theme={{
          algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        }}
      >
        {/*  <GoogleOAuthProvider clientId={clientId}> */}
        <div className="app">
          <BackToTop />
          <Layout style={{ minHeight: '100vh' }}>
            <AppHeader
              settings={settings}
              darkMode={darkMode}
              logout={logout}
              themeSwitch={themeSwitch}
              onLoginFailure={onLoginFailure}
              onLoginSuccess={onLoginSuccess}
              login={login}
            />
            <Content className={styles.siteLayout} style={{ marginTop: 64 }}>
              <div className={styles.siteLayoutBackground} style={{ minHeight: 380 }}>
                <Routes isLoggedIn={isLoaded} darkMode={darkMode} />
              </div>
            </Content>
            <FooterComponent themeMode={darkMode} />
          </Layout>
        </div>
        {/* </GoogleOAuthProvider> */}
      </ConfigProvider>
    </>
  )
}

export const App = connect(mapState, mapDispatch)(AppComponent)
